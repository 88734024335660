<template>
  <page title="佣金提现">
    <cell>
      佣金金额
      <div slot="right"> 
        <span class="text-red-500 font-medium">1939.00</span> 
        <span class="text-sm"> 元</span>
      </div>
    </cell>
    <cell>
      已结算金额
      <div slot="right"> 
        <span class="text-green-500">1000.00</span> 
        <span class="text-sm"> 元</span>
      </div>
    </cell>
    <cell no-hairline>
      未结算金额
      <div slot="right"> 
        <span class="text-orange-400">939.00</span> 
        <span class="text-sm"> 元</span>
      </div>
    </cell>
    <div class="px-4 py-3 text-xs leading-4 text-gray-light">注：未结算金额会在用户收货后，7个工作日进行结算</div>
    <div class="p-4 bg-white">
      <div class="font-medium">提现金额</div>
      <div class="flex mt-6 items-end">
        <input type="text" class="flex-1 border-none outline-none text-2xl font-light text-green-500" placeholder="输入金额" />
        <span class="text-sm text-gray-light">元</span>
      </div>
      <div class="flex justify-center mt-6">
        <btn block text-size="base">申请提现</btn>
      </div>
    </div>
    <title-bar title="提现历史" class="mt-3"></title-bar>
    <div class="bg-white">
      <list :empty="false">
        <div class="pl-4">
          <div class="pr-4 py-3 flex items-center relative hairline-bottom" v-for="(item,index) in 10" :key="index">
            <div class="flex-1 overflow-hidden">
              <div class="text-sm">申请金额: <span class="text-red-500">1939.00</span>元</div>
              <div class="mt-3 text-gray-light text-xs">申请时间：2020-10-20</div>
            </div>
            <tag :color="stateText(item).color">{{stateText(item).text}}</tag>
          </div>

        </div>
      </list>

    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ]),
  },
  methods:{
    stateText(type) {
      switch (type) {
        case 1:
          return {text:'已成功',color:'green'}
          break;
        case 2:
          return {text:'已拒绝',color:'red'}
          break;
      
        default:
          return {text:'待审核',color:'pink'}
          break;
      }
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>